import React from "react";
import "./App.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Chat from "./Chat";
import Login from "./Login";
import { useStateValue } from "./StateProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  const [{ user }, dispatch] = useStateValue();

  return (
    <div className="app">
      <Router>
        {!user ? (
          <Login />
        ) : (
          <>
            <Header />
            <div className="app__body">
              <Sidebar />
              <Switch>
                <Route path="/room/:roomId">
                  <Chat />
                </Route>
                <Route path="/">
	  <div className='welcome'>
                    <h1>Welcome To 10xCoderKids HQ</h1>
	    <h2>You can chat in #general and in #random</h2>
	    <h2>You can add channels by clicking on Add Channel</h2>
	    <h2>Once you have added the channel, u can give it's name to anyone you want.</h2>
	    <h2>And when he/her will add a channel with the same name then you can personal chat too!!</h2>
	  </div>
                </Route>
              </Switch>
            </div>
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
