import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCMUSBWodrRGhFW2M-0prAXt1TBxQuAbko",
  authDomain: "chitter-chatter-10xcoderkids.firebaseapp.com",
  databaseURL: "https://chitter-chatter-10xcoderkids.firebaseio.com",
  projectId: "chitter-chatter-10xcoderkids",
  storageBucket: "chitter-chatter-10xcoderkids.appspot.com",
  messagingSenderId: "867797703451",
  appId: "1:867797703451:web:27cebee275867aaf6ad1be"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { db, auth, provider };
export default db;