import React from 'react';
import './Header.css';
import { Avatar } from '@material-ui/core';
import { useStateValue } from './StateProvider';

function Header() {
  const [{ user }] = useStateValue();

  return <div className='header'>
    <div className='header__left'>
      <Avatar className='header__avatar' alt={user?.displayName} src={user?.photoURL} />
    </div>
    <div className='header__middle'>
      <h2>CHITTER CHATTER</h2>
    </div>
  </div>;
}

export default Header